<template>
  <div class="add-new-user">
    <ui-select
        v-model="form.role"
        :label="$t('role')"
        required-field
        :options="roles"
        :error="$v.form.role.$anyError"
    />
    <ui-input
        v-model="form.nickname"
        :label="$t('nickname')"
        required-field
        :error="$v.form.nickname.$anyError || errors.nickName"
        :error-text="getNicknameError"
        @input="errors.nickName = null"
    />
    <ui-input
        v-model="form.lastname"
        :label="$t('last-name')"
        required-field
        :error="$v.form.lastname.$anyError"
        :error-text="getLastnameError"
    />
    <ui-input
        v-model="form.firstname"
        :label="$t('first-name')"
        required-field
        :error="$v.form.firstname.$anyError"
        :error-text="getFirstnameError"
    />
    <ui-input
        v-model="form.surname"
        :label="$t('middle-name')"
    />
    <ui-select
        v-model="form.city"
        :label="$t('city')"
        required-field
        :error="$v.form.city.$error"
        :error-text="getCityLabel"
        :options="getRegions"
    />
    <ui-input
        v-model="form.inn"
        :label="$t('phone-number')"
        required-field
        v-mask="'# (###)###-##-##'"
        placeholder="X(XXX)XXX-XX-XX"
        :error="$v.form.inn.$anyError"
        :error-text="getInnError"
    />
    <ui-input
        v-model="form.email"
        label="E-mail"
        required-field
        :error="$v.form.email.$anyError"
        :error-text="getEmailError"
    />
    <ui-input
        v-model="form.password"
        :label="$t('password')"
        required-field
        show-password
        :error="$v.form.password.$anyError"
        :error-text="getPasswordError"
    />
    <ui-checkbox v-model="form.accessProcessing" :error="$v.form.accessProcessing.$anyError">
      <p>{{ $t('access-password') }}</p>
    </ui-checkbox>
    <ui-button @click="submit">{{ $t('create-participant') }}</ui-button>
    <ui-alert v-model="visibleAlert">
      <p class="ui-alert__title">{{ $t('user-successfully-added') }}</p>
      <ui-button @click="nextStep">{{ $t('good') }}</ui-button>
    </ui-alert>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect";
import UiInput from "@/components/ui/UiInput";
import UiButton from "@/components/ui/UiButton";
import { required } from "vuelidate/lib/validators";
import {mapGetters, mapActions} from "vuex";
import UiAlert from "@/components/ui/UiAlert";
export default {
  name: "AddNewUser",
  components: {
    UiAlert,
    UiCheckbox: () => import('@/components/ui/UiCheckbox'),
    UiButton,
    UiInput,
    UiSelect
  },
  data () {
    return {
      roles: [
        { name: this.$t('administrator'), value: 'Admin' },
        { name: this.$t('moderator'), value: 'Moderator' },
      ],
      errors: {},
      form: {
        role:  { name: this.$t('moderator'), value: 'Moderator' },
        city: {},
        nickname: '',
        lastname: '',
        firstname: '',
        surname: '',
        password: '',
        email: '',
        inn: '',
        accessProcessing: null,
      },
      visibleAlert: false,
    }
  },

  validations() {
    return {
      form: {
        role: {
          required,
        },
        city: {
          required,
        },
        nickname: {
          required,
        },
        lastname: {
          required
        },
        firstname: {
          required
        },
        email: {
          required
        },
        password: {
          required,
        },
        inn: {
          required
        },
        accessProcessing: {
          required
        }
      }
    }
  },

  methods: {
    ...mapActions(['addUser']),
    submit() {
      this.$v.form.$touch()
      if(!this.$v.$anyError) {
        this.addNewUser()
      }
    },

    addNewUser () {
      this.addUser(
          {
            firstName: this.form.firstname,
            lastName: this.form.lastname,
            middleName: this.form.surname,
            login: this.form.nickname,
            phone: this.form.inn,
            city: this.form.city.name,
            role: this.form.role.value,
            email: this.form.email,
            password: this.form.password,
            mustChangePassword: this.form.accessProcessing,
          }
      ).then(() => {
        this.visibleAlert = true
      }).catch((err) => {
        if(err.response.data.constraint === 'users_login_unique') {
          this.errors = {
            nickName: true
          }
        }
      })
    },

    nextStep () {
      this.visibleAlert = !this.visibleAlert
      setTimeout(() => {
        this.$router.push('/admin/users-management')
      },300)
    }
  },

  computed: {
    ...mapGetters(['getRegions']),
    getNicknameError() {
      return !this.$v.form.nickname.required ? this.$t('fill-in-the-field') : this.errors.nickName ? this.$t('nickname-already-exists') : ''
    },

    getLastnameError() {
      return !this.$v.form.lastname.required ? this.$t('fill-in-the-field') : ''
    },

    getFirstnameError() {
      return !this.$v.form.firstname.required ? this.$t('fill-in-the-field') : ''
    },

    getCityLabel(){
      return this.$t('city')
    },

    getInnError() {
      return !this.$v.form.inn.required ? this.$t('fill-in-the-field') : ''
    },

    getPasswordError() {
      return !this.$v.form.password.required ? this.$t('fill-in-the-field') : ''
    },

    getEmailError() {
      return !this.$v.form.email.required ? this.$t('fill-in-the-field') : !this.$v.form.email.email ? this.$t('error-email') : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.add-new-user {
  max-width: 434px;
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 30px 62px 30px;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 20px;
  margin-left: 151px;

  @media (max-width: 1024px) {
    margin-left: 0;
    max-width: 100%;
  }
  button {
    margin-top: 10px;
  }
}
</style>
